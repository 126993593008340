import {
  HttpClient,
  useConfigStore,
  useLoadingStore,
  useUserStore,
} from '@mosaic-wellness/redux-action-library'
import isEmpty from 'lodash/isEmpty'
import React, {useReducer} from 'react'
import Loading from './Loading'
import UserComplianceProgressComponent, {
  ComplianceStep,
} from './UserComplianceProgress.component'
import {GenericCta, useGenericCta, CTA_ACTION} from 'src/hooks/useGenericCta'
import {BACKEND_BASE_PATH} from 'src/utils/constants/breakPoints'
import Responsive from 'src/components/shared/src/ComponentsV2/HOC/Responsive'
import {LoginStatus} from '@mosaic-wellness/redux-action-library/build/redux.reducers/user/user.interface'
import {EVENT_MAP} from 'src/analytics/eventMap'

interface UserComplianceProgressProps {
  state: {
    lazy: boolean
  }
  isMobile: boolean
}

interface ComplianceState {
  loading: boolean
  data: null | {
    steps: ComplianceStep[]
    cta: GenericCta
  }
  error: any
  ctaLoading: boolean
}

const initialState: ComplianceState = {
  loading: true,
  data: null,
  error: null,
  ctaLoading: false,
}

interface Action {
  type: 'START_LOADING' | 'SUCCESS' | 'ERROR' | 'CTA_PROGRESS'
  payload?: any
}
function apiReducer(state = initialState, action: Action) {
  switch (action.type) {
    case 'START_LOADING':
      return {...state, loading: true, error: null}
    case 'SUCCESS':
      return {...state, loading: false, data: action.payload}
    case 'ERROR':
      return {...state, loading: false, error: action.payload}
    case 'CTA_PROGRESS':
      return {...state, ctaLoading: true}
    default:
      return state
  }
}

function UserComplianceProgressContainer(props: UserComplianceProgressProps) {
  const {handleCta} = useGenericCta()
  const {
    loginUser: {currentStatus},
    user: {isLoggedIn},
  } = useUserStore()
  const {isUserHydration} = useLoadingStore()

  const {analytics} = useConfigStore()
  const {state: widgetConfiguration, isMobile} = props
  const [state, dispatch] = useReducer(apiReducer, initialState)
  const handleDataFetching = React.useCallback(async () => {
    try {
      const axiosInstance = HttpClient.getAxiosInstance()

      const response = await axiosInstance
        .get(`${BACKEND_BASE_PATH}growth/personalised-assessment`)
        .then((response) => response.data)

      if (!isEmpty(response?.data)) {
        dispatch({type: 'SUCCESS', payload: response.data})
      } else {
        dispatch({type: 'ERROR', payload: new Error('Empty Response')})
      }
    } catch (error) {
      // TODO: log error
      dispatch({type: 'ERROR', payload: error})
    }
  }, [])

  const referenceForInitialLoginState = React.useRef<any>(null)
  React.useEffect(() => {
    if (isMobile) {
      handleDataFetching()
    }
  }, [currentStatus, handleDataFetching, isMobile])

  const activeStep = React.useMemo(() => {
    if (!state?.data?.steps) return null
    return state?.data?.steps.find((step) => step.status === 'in-progress')
  }, [state?.data])

  React.useEffect(() => {
    if (activeStep) {
      analytics.trigger(EVENT_MAP.HOME_STEPS_SEEN, {
        'step number': activeStep.stepNumber,
        'cta text': state?.data?.cta?.label,
      })
    }
  }, [activeStep, analytics, state?.data?.cta?.label])

  const handleCtaClick = React.useCallback(
    (cta: GenericCta) => {
      dispatch({type: 'CTA_PROGRESS'})
      if (activeStep) {
        analytics.trigger(EVENT_MAP.HOME_STEPS_CTA_CLICKED, {
          'step number': activeStep.stepNumber,
          'cta text': cta.label,
        })
      }
      setTimeout(() => {
        handleCta(cta)
      }, 300)
    },
    [activeStep, analytics, handleCta]
  )

  if (isEmpty(widgetConfiguration) || state.error || !isMobile) {
    return null
  }

  if (state.loading) {
    return <Loading />
  }

  return (
    <UserComplianceProgressComponent
      steps={state.data.steps}
      cta={state.data.cta}
      onCtaClick={handleCtaClick}
      ctaProgress={state.ctaLoading}
    />
  )
}

export default Responsive(UserComplianceProgressContainer)
