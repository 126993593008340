import React from 'react'
import {useGenericCtaV2} from 'src/hooks'
import {GenericCta} from 'src/hooks/useGenericCta'
import {StyledUserComplianceProgress} from './UserComplianceProgress.styled'
import {Typography} from 'src/components/shared/src/ComponentsV2/common/TypographyV2'
import Button from 'src/components/shared/src/dls/atomic/Button/Button'

export interface ComplianceStep {
  label: string
  name: string
  status: 'completed' | 'in-progress' | 'not-started'
}
interface UserComplianceProgressComponentProps {
  steps: ComplianceStep[]
  cta: GenericCta
  onCtaClick: (cta: GenericCta) => void
  ctaProgress: boolean
}

function UserComplianceProgressComponent({
  steps,
  cta,
  onCtaClick,
  ctaProgress = false,
}: UserComplianceProgressComponentProps) {
  const {handleCtaV2} = useGenericCtaV2()

  return (
    <StyledUserComplianceProgress>
      <div className="steps-row">
        {steps.map((step, index) => {
          const status = step.status
          return (
            <div
              key={step.name}
              className="step-wrapper"
              data-index={index}
              data-status={status}
            >
              <div
                key={index}
                className="step"
                data-index={index}
                data-status={status}
              >
                <Typography
                  variant="tag-bold"
                  customClassName={`step-label ${status}`}
                >
                  {step.label}
                </Typography>
                <span className="indicator" data-status={status} />
                <Typography
                  variant="tag-regular"
                  customClassName={`step-name ${status}`}
                >
                  <span dangerouslySetInnerHTML={{__html: step.name}}></span>
                </Typography>
              </div>
            </div>
          )
        })}
      </div>
      <div className="cta-row">
        <Button
          variant={cta.variant}
          size="MEDIUM"
          label={cta.label}
          onClick={() => onCtaClick(cta)}
          disabled={ctaProgress}
          fullWidth
        />
      </div>
    </StyledUserComplianceProgress>
  )
}

export default UserComplianceProgressComponent
