import styled from 'styled-components';

export const StyledButton = styled.button`
  border: none;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s, color 0.2s;
  border-radius: 8px; 
  border-width: 1px;
  border-style: solid;
  display: flex;
  align-items: center;

  &[data-variant='PRIMARY'] {
    background-color: var(--dls-brand-primary-color-500);
    color: white;
    &:hover {
      background-color: var(--dls-brand-primary-color-400);
    }
  }
  &[data-variant='SECONDARY'] {
    background-color: #ffffff;
    color: #333;
    &:hover {
      background-color: var(--dls-brand-primary-color-100);
    }
  }
  &[data-variant='TERTIARY'] {
    border: none;
    background-color: transparent;
    color: var(--dls-brand-primary-color-500);
    &:hover {
      color: var(--dls-brand-primary-color-400)
    }
  }

  &[data-size='LARGE'] {
    height: 48px;
    padding: 0 16px;
    font-size: 18px;
  }
  &[data-size='MEDIUM'] {
    height: 40px;
    border-radius: 8px;
    padding: 0px 12px;
    font-size: 14px;
  }
  &[data-size='SMALL'] {
    height: 32px;
    padding: 0px 8px;
    font-size: 12px;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  &:active {
    transform: scale(0.98);
  }

  // Icon styles
  & > .icon-left {
    margin-right: 8px;
  }

  & > .icon-right {
    margin-left: 8px;
  }

  &[data-isfullwidth='true'] {
    width: 100%;
  }
`;
