import styled from 'styled-components'

export const LoadingContainer = styled.div`
  display: flex;
  width: '100%';
  height: 136px;
`

export const StyledUserComplianceProgress = styled.div`
  display: flex;
  width: '100%';
  flex-direction: column;
  padding: 12px 16px 0;
  .steps-row {
    display: flex;
    width: '100%';
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 16px;
    .step-wrapper {
      display: flex;
      width: 33%;
      flex-direction: row;
      position: relative;

      &[data-index='0'] {
        justify-content: flex-start;
      }
      &[data-index='1'] {
        justify-content: center;
      }
      &[data-index='2'] {
        justify-content: flex-end;
      }

      &[data-index='1']::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 2px;
        background-color: grey;
        top: 25px;
        left: -60%;
        z-index: 1;
      }

      &[data-status='complete'],
      &[data-status='in-progress'] {
        &[data-index='1']::before {
          background-color: var(--dls-success-color-500);
        }
      }

      &[data-index='1']::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 2px;
        background-color: var(--dls-disabled-text-colors);
        top: 25px;
        left: 60%;
        z-index: 1;
      }
      &[data-status='complete'] {
        &[data-index='1']::after {
          background-color: var(--dls-success-color-500);
        }
      }
    }

    .step {
      width: max-content;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      align-content: flex-start;
      justify-content: space-evenly;

      .indicator {
        display: inline-block;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        border: 4px solid;
        margin-bottom: 6px;
        &[data-status='complete'] {
          border: 4px solid var(--dls-success-color-500);
        }
        &[data-status='in-progress'] {
          border: 4px solid var(--dls-brand-text-colors);
        }
        &[data-status='not-started'] {
          border: 4px solid var(--dls-disabled-text-colors);
        }
      }

      .step-label,
      .step-name {
        text-align: center;
      }

      .step-label {
        margin-bottom: 6px;
        &.not-started {
          color: var(--dls-disabled-text-colors);
        }
        &.in-progress {
          color: var(--dls-brand-text-colors);
        }
        &.complete {
          color: var(--dls-success-color-500);
        }
      }

      .step-name {
        flex-grow: 1;
        &.not-started {
          color: var(--dls-disabled-text-colors);
        }
        &.in-progress {
          color: var(--dls-brand-text-colors);
        }
        &.complete {
          color: var(--dls-success-color-500);
        }
      }
    }
  }
`
